<template>
	<div class="trialinviter alertDialogPage">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="协助邀请"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 用户列表 -->
		<div class="users">
			<div class="user" v-for="item in list" :key="item.id">
				<div class="user__base">
					<van-image class="center_avatar" round :src="item.avatar" fit="cover"/>
					{{item.username}}
				</div>
				<div
					class="user__btn1"
					v-if="item.in_scsj == 0"
					@click="handInviter(item)"
				>邀请协助</div>
				<div class="user__btn2" v-if="item.in_scsj != 0">正在试驾</div>
			</div>
		</div>
		
		<van-dialog v-model="show" @cancel="handcancel" @confirm="handInviterReq" title="发送邀请" show-cancel-button>
			<div class="user__inviter">
					确定邀请<span class="user__inviter__name">{{inviterName}}</span>参加本次协助试驾吗？
			</div>
		</van-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			inviterId: 0,
			inviterName: '',
			number: '',
			list: [],
			show:false,
		};
	},
	mounted() {
		if (this.$route.query?.number) this.number = this.$route.query.number
		this.getUserList()
	},
	methods: {
		handcancel(){
			this.show = false
		},
		// 邀请
		handInviter(e) {
			this.inviterId = e.id
			this.inviterName = e.username
			this.show = true
		},
		async handInviterReq(e) {
			const {data: result} = await this.$http.post('/api/scsj/send_teamwork_invite', {
				number: this.number,
				user_id: this.inviterId
			})
			if (result.code == 1) {
				this.$toast.success(result.msg)
				this.getUserList()
			} else {
				this.$toast.fail(result.msg)
			}
		},
		
		// 用户列表
		async getUserList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_teamwork_user_list', {})
			if (result.code == 1) {
				this.list.length = 0
				result.data.res.forEach(v => {
					v.avatar = this.serverUrl(v.avatar)
					this.list.push(v)
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.users{
	padding: 40px;
	.user{
		margin-bottom: 32px;
		padding: 28px 40px 28px 28px;
		box-sizing: border-box;
		background: #333;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__base{
			display: flex;
			align-items: center;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			height: 84px;
			.center_avatar{
				margin-right: 24px;
				width: 84px;
				height: 84px;
				border-radius: 50%;
			}
		}
		&__btn1{
			width: 204px;
			border: 1px solid #919191;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #ECECEC;
			text-align: center;
			line-height: 80px;
		}
		&__btn2{
			width: 204px;
			border: 1px solid #4C4C4C;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #828282;
			text-align: center;
			line-height: 80px;
		}
	}
}
.user__inviter{
	font-family: HYQiHeiY3-45;
	text-align: center;
	padding: 20px 0;
	&__name{
		color: #FA0037;
	}
}
</style>
